<template>
  <form id="frm_action" class="parent_page_detail">
  <div class="p-grid list_group_order">
    <div class="p-col-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <ProgressBar v-if="display_progress_bar == true" :value="value_process_import">
          {{name_process_import}}: {{value_process_import}}%
        </ProgressBar>
        <div  class="p-col-12 group-button-list-top group-button-list-top-custom  " :class="dislay_button==0 ? 'list-button':'' " >
<!--          <Button
              label="Xác nhận soạn hàng"
              class=" style-button-common"
          />
          <Button
              label="Xác nhận soạn hàng"
              class=" style-button-common"
          />-->

          <Button v-if="display_order_has_been_printed == true"
              label="Đã in đơn soạn hàng"
              class=" style-button-common main-style-button"
              @click="process_click_status($constants.STATUS_ORDER.ORDER_HAS_BEEN_PRINTED,'Đã in đơn soạn hàng','Xác nhận đã in đơn soạn hàng')"
          />
          <Button v-if="display_confirm_preparation_goods == true"
              label="Xác nhận soạn hàng"
              class=" style-button-common main-style-button"
              @click="process_click_status($constants.STATUS_ORDER.CONFIRM_PREPARATION_GOODS,'Xác nhận soạn hàng','Xác nhận soạn hàng')"
          />

          <Button
              label="Cập nhật kiện hàng"
              v-if="display_packaged == true"
              class=" style-button-common main-style-button"
              @click="process_click_status($constants.STATUS_ORDER.PACKAGED,'Cập nhật kiện hàng')"
          />
          <Button
              v-if="dislay_button==0 && role.updated_dispatcher_address == true"
              label="Xác nhận địa chỉ điều phối"
              class=" style-button-common main-style-button"
              @click="process_click_status($constants.STATUS_ORDER.UPDATED_DISPATCHER_ADDRESS,'Xác nhận địa chỉ điều phối','Xác nhận địa chỉ điều phối')"
          />
          <Button
              v-if="dislay_button==1 && display_goods_out_warehouse"
              label="Hàng ra khỏi kho"
              class=" style-button-common main-style-button"
              @click="process_click_status($constants.STATUS_ORDER.GOODS_OUT_WAREHOUSE,'Hàng ra khỏi kho','Xác nhận hàng ra khỏi kho')"
          />
          <Button
              v-if="dislay_button==1 && display_goods_leave_company"
              label="Hàng ra khỏi công ty"
              class=" style-button-common main-style-button"
              @click="process_click_status($constants.STATUS_ORDER.GOODS_LEAVE_COMPANY,'Hàng ra khỏi công ty','Xác nhận hàng ra khỏi công ty')"
          />

          <Button
              v-if="dislay_button==1 && this.display_delivery_status == true"
              label="Tình trạng giao hàng"
              class=" style-button-common main-style-button"
              @click="process_click_status('DELIVERY_STATUS','Tình trạng giao hàng','Xác nhận')"
          />
          <Button
              v-if="dislay_button==0 && display_coordination_completed"
              label="Cập nhật điều phối"
              class=" style-button-common main-style-button"
              @click="process_click_status($constants.STATUS_ORDER.COORDINATION_COMPLETED,'Cập nhật điều phối','Cập nhật điều phối')"
          />
          <Button
              v-if=" this.display_transfer_hsct_accounting == true"
              label="Chuyển HSCT lên Kế toán"
              class=" style-button-common main-style-button"
              @click="process_click_status($constants.STATUS_ORDER.TRANSFER_HSCT_ACCOUNTING,'Chuyển HSCT lên Kế toán','Xác nhận')"
          />
          <Button
              v-if="dislay_button==0 && display_enough_documents_close_application"
              label="Xác nhận đóng đơn & đủ chứng từ"
              class=" style-button-common main-style-button"
              @click="process_click_status($constants.STATUS_ORDER.ENOUGH_DOCUMENTS_CLOSE_APPLICATION,'Xác nhận đóng đơn & đủ chứng từ','Xác nhận đóng đơn & đủ chứng từ')"
          />

          <div class="import-order-btn">
            <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ref="files" :model="files" style="display: none" id="input-file-order" type="file"  @change="chooseFile">
          <Button
              v-if="dislay_button==1 && role.import == true"
              label="Import"
              class=" style-button-common main-style-button "
              @click="click_import_order()"
          />
          </div>


        </div>

        <div  v-if="dislay_button==1 && role.import == true" class="p-col-12 group-button-list-top group-button-list-top-custom  " :class="dislay_button==0 ? 'list-button':'' " >

          <a class="download_file_sample" href="/template/Order_import_sample_file.xlsx" download target="_blank">Tải file import mẫu</a>
        </div>
        <!--{{listData}}-->
        <BaseDatatable
          :key="componentKey"
          :tableData="listData"
          :model="dataModel"
          :options="options"
          :getFilterOptions="getFilterOptions"
          :actions="getRowActions()"
          :whereKeyList="whereKeyList"
          v-on:action-click="onActionClicked"
          v-on:action-list_select_item="action_list_select_item"
          :filterDefaultCustom="filterDefaultCustom"
        ></BaseDatatable>
        <!-- <Popup
          :key="editDialogKey"
          :show="displayDialog"
          :objKey="editId"
          :objModelName="modelName"
          :mode="dialogMode"
          @onChange="onChange()"
        ></Popup> -->

        <Dialog
          header="Xóa"
          :visible.sync="showDeleteDialog"
          :contentStyle="{overflow: 'visible'}"
          :modal="true"
        >
          Bạn có chắc muốn xóa <strong>{{this.deleteName}}</strong>
          <template
            #footer
          >
            <Button label="Xóa" @click="deleteData()" icon="pi pi-check" class="p-button-danger" />
            <Button
              label="Hủy"
              @click="showDeleteDialog = false"
              icon="pi pi-times"
              class="p-button-warning callback-btn"
            />
          </template>
        </Dialog>
        <Dialog
            :modal="true"
            :showHeader="true"
            position="center" class="custom-popup-common style-popup-update-pakage" header="Cập nhật kiện hàng" footer="Footer" :visible.sync="data_popup_update_pakage.display">
          <div class="text_confirm_popup">Bạn xác nhận đơn hàng đã được soạn hàng xong với thông tin số kiện được liệt kê bên dưới và sẵn sàng cho hoạt động điều phối.</div>
          <div class="group-input-popup" v-if="data_popup_update_pakage.obj_data.is_multiple ==false">
            <label class="p-col-fixed txt-right">Mã đơn hàng</label>
            <div class="p-col">
              <div class="group-input-popup d-flex">
                <InputText :key="code_key" v-focus id="code_order_pakage"  v-model="data_popup_update_pakage.obj_data.code"  type="text" class="p-col-width style_inpuit_order" placeholder="Nhập" @input="changeOrderPackage()" />
                <Button icon="fa fa-qrcode" v-show="is_mobile_web" @click="scan_visible2 = !scan_visible2"/>
              </div>
              <Scan @result_code="success_scan2" :invisible="scan_visible2" v-if="scan_visible2"/>
            </div>
          </div>
          <div class="group-input-popup" v-if="data_popup_update_pakage.obj_data.is_multiple ==true">
            <label class="p-col-fixed txt-right">Mã đơn hàng</label>
            <div class="p-col">
              <Textarea disabled="disabled" class="p-col-width style_inpuit_order" v-model="formattedListCode" :autoResize="true" rows="1" cols="30" />
  <!--              <InputText    v-model="data_popup_update_pakage.obj_data.code"  type="text" class="p-col-width style_inpuit_order" placeholder="Nhập" />-->
            </div>
          </div>
          <div class="group-input-popup">
            <div class="p-col">
              <Checkbox id="data_popup_update_pakage_combine_packages" v-model="data_popup_update_pakage.obj_data.combine_packages" :binary="true" />
              <label for="data_popup_update_pakage_combine_packages" class="title_check_box">Ghép kiện</label>
            </div>
          </div>

          <div class="group-input-popup" v-if="data_popup_update_pakage.obj_data.combine_packages ==true">
            <label class="p-col-fixed txt-right">Đơn hàng muốn ghép</label>
            <div class="p-col">
              <div class="group-input-popup d-flex">
                <InputText id="code_combine_packages" v-model="data_popup_update_pakage.obj_data.code_combine_packages"  type="text" class="p-col-width" placeholder="Nhập" @input="change_code_combine_packages()" />
                <Button icon="fa fa-qrcode" v-show="is_mobile_web" @click="scan_visible = !scan_visible"/>
              </div>
              <Scan @result_code="success_scan" :invisible="scan_visible" v-if="scan_visible"/>
            </div>
          </div>
          <!--

          <li v-for="({ message }, index) in items">
            {{ message }} {{ index }}
          </li>
          -->

          <div class="group-input-popup" v-for="(item_pakage, index_pa) in data_popup_update_pakage.list_pakage_data" :key="item_pakage.index_pa">
            <div class="item-left-popup" style="">
              <label class="p-col-fixed txt-right" v-if="index_pa==0">Kiện hàng</label>
              <div class="p-col ">
                <Dropdown :disabled="data_popup_update_pakage.obj_data.combine_packages ==true" class="pakage_popup_dropdown" v-model="item_pakage.parcel_id" :options="item_pakage.list_parcel_key" @before-show="before_show_parcel(index_pa,item_pakage.parcel_id)" optionLabel="name" optionValue="id" placeholder="Chọn" />
                <!--                <InputText required v-model="user.fullname"  id="name" type="text" class="p-col-width" placeholder="Nhập tên" />-->
              </div>
            </div>
            <div class="item-right-popup" style="">
              <label class="p-col-fixed txt-right" v-if="index_pa==0">Số lượng</label>
              <div class="p-col style_group_pakage">
                <InputNumber :disabled="data_popup_update_pakage.obj_data.combine_packages ==true"  v-model="item_pakage.parcel_qty"  type="text" class="p-col-width popup_qty_pakage" placeholder="Nhập" />
                <img v-if="data_popup_update_pakage.obj_data.combine_packages ==false"  @click="delete_pakage_popup(item_pakage.key_data)" class="style_icon_del_pakage" style="" src="/assets/images/icon/ic_delete.svg">
              </div>
            </div>
          </div>

          <div class="group-input-popup" v-if="data_popup_update_pakage.obj_data.combine_packages ==false">
            <div class="add_item_popup" @click="add_pakage_popup()">Thêm kiện hàng</div>
          </div>

          <template #footer>
            <Button
                icon="pi pi-times" iconPos="left"
                label="Hủy"
                @click="data_popup_update_pakage.display = false"
                class="p-button-success style-button-cancel-popup "
            />
            <Button
                icon="pi pi-check" iconPos="left"
                label="Xác nhận"
                @click="process_submit_pakage()"
                class="p-button-success main-style-button"
            />
          </template>
        </Dialog>
        <Dialog
            :modal="true"
            :showHeader="true"
            position="center" class="custom-popup-common style-popup-confirm-address" header="Xác nhận địa chỉ điều phối" footer="Footer" :visible.sync="data_popup_confirm_address.display">
          <div class="text_confirm_popup">Bạn xác nhận rằng cập nhật địa chỉ điều phối cho các đơn hàng này là đúng.</div>
          <template #footer>
            <Button
                icon="pi pi-times" iconPos="left"
                label="Hủy"
                @click="data_popup_confirm_address.display = false"
                class="p-button-success style-button-cancel-popup "
            />
            <Button
                icon="pi pi-check" iconPos="left"
                label="Xác nhận"
                @click="process_confirm_address()"
                class="p-button-success main-style-button"
            />
          </template>
        </Dialog>
        <Dialog
            :modal="true"
            :showHeader="true"
            position="center" class="custom-popup-common style-popup-update-pakage" header="Import không thành công" footer="Footer" :visible.sync="data_popup_import_faild.display">
          <div class="text_confirm_popup">{{data_popup_import_faild.text_err}}</div>
          <div v-if="data_popup_import_faild.text_err1" class="text_err_row">{{data_popup_import_faild.text_err1}}</div>
          <div v-if="data_popup_import_faild.text_err2" class="text_err_row">{{data_popup_import_faild.text_err2}}</div>
<!--          <div class="group-input-popup">
            <label class="p-col-fixed txt-right">Mã đơn hàng</label>
            <div class="p-col">
              <InputText id="code_order_pakage"  v-model="data_popup_update_pakage.obj_data.code"  type="text" class="p-col-width style_inpuit_order" placeholder="Nhập" />
            </div>
          </div>
          <div class="group-input-popup">
            <div class="p-col">
              <Checkbox id="data_popup_update_pakage_combine_packages" v-model="data_popup_update_pakage.obj_data.combine_packages" :binary="true" />
              <label for="data_popup_update_pakage_combine_packages" class="title_check_box">Ghép kiện</label>
            </div>
          </div>-->

<!--          <div class="group-input-popup" v-if="data_popup_update_pakage.obj_data.combine_packages ==true">
            <label class="p-col-fixed txt-right">Đơn hàng muốn ghép</label>
            <div class="p-col">
              <InputText id="code_combine_packages" v-model="data_popup_update_pakage.obj_data.code_combine_packages"  type="text" class="p-col-width" placeholder="Nhập" />
            </div>
          </div>-->
          <!--

          <li v-for="({ message }, index) in items">
            {{ message }} {{ index }}
          </li>
          -->

<!--          <div class="group-input-popup" v-for="(item_pakage, index_pa) in data_popup_update_pakage.list_pakage_data" :key="item_pakage.index_pa">
            <div class="item-left-popup" style="">
              <label class="p-col-fixed txt-right" v-if="index_pa==0">Kiện hàng</label>
              <div class="p-col ">
                <Dropdown class="pakage_popup_dropdown" v-model="item_pakage.parcel_id" :options="data_popup_update_pakage.list_parcel" optionLabel="name" optionValue="id" placeholder="Chọn" />
                &lt;!&ndash;                <InputText required v-model="user.fullname"  id="name" type="text" class="p-col-width" placeholder="Nhập tên" />&ndash;&gt;
              </div>
            </div>
            <div class="item-right-popup" style="">
              <label class="p-col-fixed txt-right" v-if="index_pa==0">Số lượng</label>
              <div class="p-col style_group_pakage">
                <InputText  v-model="item_pakage.parcel_qty"  type="text" class="p-col-width popup_qty_pakage" placeholder="Nhập" />
                <img  @click="delete_pakage_popup(item_pakage.key_data)" class="style_icon_del_pakage" style="" src="/assets/images/icon/ic_delete.svg">
              </div>
            </div>
          </div>-->

          <div class="group-input-popup">
            <div class="add_item_popup" @click="download_file_err()">Bấm vào đây để tải file lỗi</div>
          </div>

          <template #footer>
            <Button
                icon="pi pi-times" iconPos="left"
                label="Hủy bỏ"
                @click="data_popup_import_faild.display = false"
                class="p-button-success style-button-cancel-popup "
            />
            <Button
                icon="pi pi-check" iconPos="left"
                label="Đã hiểu"
                @click="data_popup_import_faild.display = false"
                class="p-button-success main-style-button"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
  </form>
</template>

<script>
import DataServices from '@/core/DataServices'
import BaseDatatable from './list'
import gql from "graphql-tag";
import AuthenticationApp from "@/core/AuthenticationApp";
import ApiRepository from "@/core/ApiRepository";
import Scan from '@/components/Scan';
//import configJson from '../../config';
//import axios from 'axios'
//import moment from "moment-timezone";
// import Popup from './popup'
export default {
  components: {
    BaseDatatable,
    Scan
    // Popup
  },
  directives: {
    focus: {
      // Directive definition
      inserted(el) {
        // Focus the element when inserted into the DOM
        el.focus();
      }
    }
  },
  computed: {
    formattedListCode() {
      return this.data_popup_update_pakage.obj_data.list_code.join(', '); // Biến đổi mảng thành chuỗi, các phần tử cách nhau bằng dấu phẩy
    }
  },
  data() {
    return {
      code_key:1,
      scan_visible2: false,
      scan_visible: false,
      is_mobile_web: false,
      ishidden:true,
      list_map_code_order:{},
      name_process_import:'',
      value_process_import:0,
      display_progress_bar :false,
      files:[],
      dislay_button:1,
      data_popup_confirm_address:{
        display:false,
      },
      data_popup_import_faild:{
        display:false,
        text_err:"",
        text_err1:null,
        text_err2:null,
        link_err:"",
        /*list_pakage_data:[],
        list_parcel:[],
        obj_data:{
          combine_packages:false,
          code_combine_packages:'',
          id_combine_packages:''
        }*/
      },
      data_popup_update_pakage:{
        display:false,
        list_pakage_data:[],
        list_parcel:[],
        obj_data:{
          list_order_check:[],
          is_multiple:false,
          list_code:[],
          combine_packages:false,
          code_combine_packages:'',
          id_combine_packages:''
        }
      },
      order_id_pakage:null,
      list_select_item:[],
      list_order_select_item:[],
      filterOptions: {},
      options: [],
      listData: [],
      modelName: '',
      dataModel: null,
      title: '',
      componentKey: 0,
      editId: null,
      displayDialog: false,
      editDialogKey: 0,
      dialogMode: 'edit',
      deleteId: null,
      deleteName: null,
      showDeleteDialog: false,
      whereKeyList:{
        where_key: {
          /*accounts: {
            role: {_eq: "admin"},
            deleted: {_eq: false}
          },*/
          deleted: {_eq: false}
        }
      },
      page_transaction:null,
      role: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        order_has_been_printed: false,
        confirm_preparation_goods: false,
        packaged: false,
        confirm_dispatcher_address: false,
        updated_dispatcher_address: false,
        coordination_completed: false,
        goods_out_warehouse: false,
        goods_leave_company: false,
        delivery_status: false,
        transfer_hsct_accounting: false,
        enough_documents_close_application: false,
        canceled: false,
        import: false,
      },
      display_order_has_been_printed:null,
      display_confirm_preparation_goods:null,
      display_packaged:null,
      display_confirm_dispatcher_address:null,
      display_updated_dispatcher_address:null,
      display_coordination_completed:null,
      display_goods_out_warehouse:null,
      display_goods_leave_company:null,
      display_delivery_status:null,
      display_transfer_hsct_accounting:null,
      display_enough_documents_close_application:null,
      display_canceled:null,
      baseUrl:'',
      list_status_change:{},
      filterDefaultCustom:null,
      item_parcel_ol:null,//item kiện hàng khô  nhỏ
    }

  },
  mounted () {
    this.initParams();
    var isMobile = window.orientation > -1;
    if (isMobile) {
      this.is_mobile_web = true;
    }
  },
  async created() {
   // console.log("this.$CoreService.baseurl():",this.$CoreService.baseurl())
   // var envConfig = (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'production' || process.env.NODE_ENV == 'test') ? process.env.NODE_ENV:'development';
    //this.baseUrl = configJson[envConfig].base_url_api;

    this.modelName = this.$route.meta.model;
    this.dataModel = DataServices.getModel(this.modelName);
    console.log('this.dataModel:',this.dataModel);
    this.title = this.dataModel.name
    this.$store.commit('setLoading', true);
    if(this.$route.params.reload) {
      window.location.reload();
    }
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ORDER);
    console.log("arr_role",arr_role)
    if (arr_role.indexOf(this.$constants.ROLE.VIEW) < 0){
      this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
    }
    this.role.order_has_been_printed = (arr_role.indexOf(this.$constants.ROLE.ORDER_HAS_BEEN_PRINTED) < 0) ? false:true;
    this.display_order_has_been_printed = this.role.order_has_been_printed
console.log("display_order_has_been_printed",this.display_order_has_been_printed)
    this.role.confirm_preparation_goods = (arr_role.indexOf(this.$constants.ROLE.CONFIRM_PREPARATION_GOODS) < 0) ? false:true;
    this.display_confirm_preparation_goods = this.role.confirm_preparation_goods

    this.role.packaged = (arr_role.indexOf(this.$constants.ROLE.PACKAGED) < 0) ? false:true;
    this.display_packaged = this.role.packaged

    this.role.confirm_dispatcher_address = (arr_role.indexOf(this.$constants.ROLE.CONFIRM_DISPATCHER_ADDRESS) < 0) ? false:true;
    this.display_confirm_dispatcher_address = this.role.confirm_dispatcher_address

    this.role.updated_dispatcher_address = (arr_role.indexOf(this.$constants.ROLE.UPDATED_DISPATCHER_ADDRESSS) < 0) ? false:true;
    this.display_updated_dispatcher_address = this.role.updated_dispatcher_address

    this.role.coordination_completed = (arr_role.indexOf(this.$constants.ROLE.COORDINATION_COMPLETED) < 0) ? false:true;
    this.display_coordination_completed = this.role.coordination_completed


    this.role.goods_out_warehouse = (arr_role.indexOf(this.$constants.ROLE.GOODS_OUT_WAREHOUSE) < 0) ? false:true;
    this.display_goods_out_warehouse = this.role.goods_out_warehouse

    this.role.goods_leave_company = (arr_role.indexOf(this.$constants.ROLE.GOODS_LEAVE_COMPANY) < 0) ? false:true;
    this.display_goods_leave_company = this.role.goods_leave_company

    this.role.delivery_status = (arr_role.indexOf(this.$constants.ROLE.DELIVERY_STATUS) < 0) ? false:true;
    this.display_delivery_status = this.role.delivery_status

    this.role.delivery_status = (arr_role.indexOf(this.$constants.ROLE.DELIVERY_STATUS) < 0) ? false:true;
    // this.role.delivery_status = this.role.delivery_status

    this.role.transfer_hsct_accounting = (arr_role.indexOf(this.$constants.ROLE.TRANSFER_HSCT_ACCOUNTING) < 0) ? false:true;
    this.display_transfer_hsct_accounting = this.role.transfer_hsct_accounting

    this.role.enough_documents_close_application = (arr_role.indexOf(this.$constants.ROLE.ENOUGH_DOCUMENTS_CLOSE_APPLICATION) < 0) ? false:true;
    this.display_enough_documents_close_application = this.role.enough_documents_close_application

    this.role.canceled = (arr_role.indexOf(this.$constants.ROLE.CANCELED) < 0) ? false:true;
    this.display_canceled = this.role.canceled

    this.role.import = (arr_role.indexOf(this.$constants.ROLE.IMPORT) < 0) ? false:true;

    this.get_list_status_change()
  },
  watch: {
    '$route': 'initParams'
  },
  methods: {
    async changeOrderMultilplePackage() {
      var data_res =await this.$CoreService.checkOLOrderCustomer(this.data_popup_update_pakage.obj_data.list_code);
      console.log("data_res:",data_res)
      if (data_res.is_ol == 1){
        this.defaultPackageOl();
      }else {
        this.data_popup_update_pakage.list_pakage_data = [];
        this.add_pakage_popup();
      }
    },
     async changeOrderPackage() {
      var code_order = this.data_popup_update_pakage.obj_data.code;
      var list_code = [code_order];
      var data_res =await this.$CoreService.checkOLOrderCustomer(list_code);
      console.log("data_res:",data_res)
      if (data_res.is_ol == 1){
        this.defaultPackageOl();
      }else {
        this.data_popup_update_pakage.list_pakage_data = [];
        this.add_pakage_popup();
      }
    },
    defaultPackageOl() {
      this.data_popup_update_pakage.list_pakage_data = [];
      console.log("this.item_parcel_ol.id:",this.item_parcel_ol.id)
      var item_pakage = {
        parcel_id: (this.item_parcel_ol) ? this.item_parcel_ol.id:null,
        parcel_qty:1,
        key_data:(new Date()).getTime()+this.$commonFuction.makeId(8),
        list_parcel_key:[]
      }
      this.data_popup_update_pakage.list_pakage_data.push(item_pakage)
      this.data_popup_update_pakage.list_pakage_data[0]["list_parcel_key"] = this.data_popup_update_pakage.list_parcel;
    },
    success_scan2(data) {
      this.data_popup_update_pakage.obj_data.code = data;
      this.scan_visible2 = false;
    },
    success_scan(data){
      this.data_popup_update_pakage.obj_data.code_combine_packages = data;
      this.scan_visible = false;
    },
    async before_show_parcel(index_pa,parcel_id=null) {
      console.log("gggggggggg:",index_pa);
      var list_parcel = this.data_popup_update_pakage.list_pakage_data;
      //lấy danh sách id ko thuộc id hiện tại
      var list_id_not_current = [];
      console.log("list_parcellist_parcel:",list_parcel)
      for (var i=0;i<list_parcel.length;i++){
        var item_data = list_parcel[i];
         var id_ = item_data.parcel_id;
         if (id_ != parcel_id){
           list_id_not_current.push(id_)
         }
      }
      console.log("list_id_not_current:",list_id_not_current)
      console.log("parcel_id:",parcel_id)
      var list_parcel_default = this.data_popup_update_pakage.list_parcel
      var list_res = [];
      for (var j=0;j<list_parcel_default.length;j++){
        var item_data1 = list_parcel_default[j];
        var id_check = item_data1.id
        if (list_id_not_current.indexOf(id_check) ==-1){
          //lấy item data ko tồn tại trong mảng
          list_res.push(item_data1)
        }
      }
      this.data_popup_update_pakage.list_pakage_data[index_pa]["list_parcel_key"] = list_res;
    },
    async load_list_order_by_code(list_code,modelName) {
      //this.obj_id
      var gqLQueryListOrderDocs = DataServices.getList(modelName);
      var listDisplayCompany = AuthenticationApp.getListCompany();

      var where_user_docs = {
        code: {_in: list_code},
      }

      if (listDisplayCompany != null)
      {
        where_user_docs['company_id'] = {_in: listDisplayCompany};
      }
      var resData_docs = await this.$apollo.mutate({
        mutation: gqLQueryListOrderDocs,
        variables: {
          where_key:where_user_docs ,
          /*offset:offset,
          limit:limit,*/
          orderBy:{created_at: 'desc'}
        }
      });
      var listData = resData_docs.data['order'];
      var listdata_convert = []
      //var count_stt = 0;
      for (var i=0;i<listData.length;i++){
        var item_order = listData[i]

        /*var order_shipping_code = '';
        var name_warehouse = '';
        var detail_text = '';
        if (this.modelName=='order_custom_warehouse'){
          //lấy mã chuyến
          if (!this.$commonFuction.isEmpty(item_order.obj_order_shipping_items)){
            order_shipping_code = (!this.$commonFuction.isEmpty(item_order.obj_order_shipping_items.order_shipping)) ? item_order.obj_order_shipping_items.order_shipping.code:'';
          }
          //lấy tên kho
          if (!this.$commonFuction.isEmpty(item_order.obj_order_item)){

            name_warehouse = (!this.$commonFuction.isEmpty(item_order.obj_order_item.warehouseByWarehouseId)) ? item_order.obj_order_item.warehouseByWarehouseId.name:'';
          }
          //chi tiet
        }
        item_order["order_shipping_code"] = order_shipping_code;
        item_order["name_warehouse"] = name_warehouse;
        console.log('name_warehousename_warehouse:',name_warehouse)
        item_order["detail_text"] = detail_text;*/
        listdata_convert.push(item_order)
      }
      return listdata_convert;
    },
    async change_code_combine_packages() {
      console.log("asdasdaschange_code_combine_packages:")
        var code_order = this.data_popup_update_pakage.obj_data.code_combine_packages;
        if (!this.$commonFuction.isEmpty(code_order)) {
          var list_code = [];
          list_code.push(code_order)
          var listdata_convert = await this.load_list_order_by_code(list_code, 'order')
          var item_order = (listdata_convert.length > 0) ? listdata_convert[0] : null;
          if (item_order){
            console.log("item_order:",item_order);
            //var order_group_parcel_id = item_order.order_group_parcel_id
            if (item_order.order_group_parcel_id){
              var data_res =await this.$CoreService.getPackage(item_order.order_group_parcel_id);
              for (var k=0;k<data_res.length;k++){
                data_res[k]["list_parcel_key"] = this.data_popup_update_pakage.list_parcel
              }
              this.data_popup_update_pakage.list_pakage_data = data_res
            }else {
              this.data_popup_update_pakage.list_pakage_data = [];
            }
          }else {
            this.data_popup_update_pakage.list_pakage_data = [];
          }
        }else {
          this.data_popup_update_pakage.list_pakage_data = [];
        }
      },
    async process_confirm_address() {
      /* if (
           this.type_status ==this.$constants.STATUS_ORDER.ORDER_HAS_BEEN_PRINTED
           || this.type_status ==this.$constants.STATUS_ORDER.CONFIRM_PREPARATION_GOODS
           || this.type_status ==this.$constants.STATUS_ORDER.GOODS_OUT_WAREHOUSE
           || this.type_status ==this.$constants.STATUS_ORDER.GOODS_LEAVE_COMPANY
       ) {*/

      var dataRequest = {
        list_data_order:this.list_order_select_item,
        code_change:this.$constants.STATUS_ORDER.UPDATED_DISPATCHER_ADDRESS,
      }
      /*if (this.type_status =='DELIVERY_STATUS'){
        dataRequest["list_data_order_faild"] = this.listData1
      }*/
      console.log("this.list_order_select_item:",this.list_order_select_item)
      var that = this;
      var data_res =await this.$CoreService.update_status_multil(dataRequest);
      var status = data_res.status
      if (status == true){
        that.$toast.add({
          severity: "success",
          summary: "Thông báo",
          detail: "Cập nhật thành công",
          life: 3000,
        });
        window.location.href = '/admin/list-order'
        /* location.reload()*/
      }else {
        var list_err = data_res.list_err
        if (list_err && list_err.length>0){
          alert("Các đơn hàng không thể thay đổi trạng thái này:" + list_err.join(', '));
        }else {
          that.$toast.add({
            severity: "error",
            summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
            detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
            life: 3000,
          });
        }
      }
      //}
    },
    async update_status_multil(dataRequest) {
      try {
        const headers = {
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
        await ApiRepository.post('/_api/order/update-status-order-multil', dataRequest, {headers});
        return true
      } catch (err) {
        console.log(err);
        return false;
      }
    },
    async get_list_status_change() {
      try {
        var dataRequest = {
          //order_id: this.model.id,
        };
        const headers = {
         // "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
        var list_action_detail = await ApiRepository.post('/_api/order/get_list_status_change', dataRequest, {headers});
        var STATUS_NEXT_ORDER = (list_action_detail["data"]["data"]["STATUS_NEXT_ORDER"]) ? list_action_detail["data"]["data"]["STATUS_NEXT_ORDER"]:{}
        this.list_status_change = STATUS_NEXT_ORDER
      } catch (err) {
        console.log(err);
      }
    },
    update_process_bar(name,percent) {
      this.display_progress_bar = true
      this.name_process_import = name
      this.value_process_import = percent
    },
    async download_file_err() {

     // var urL_report = item_check.urL_report
      //window.open(urL_report, '_blank');

      var dataResponseDownload = await this.$CoreService.callApiDownloadPost('_api/common/read_file_url',{file_url:this.data_popup_import_faild.link_err},{})
      var url = URL.createObjectURL(new Blob([dataResponseDownload.data], {
        type: 'application/vnd.ms-excel'
      }))
      const link = document.createElement('a')
      link.href = url;
      //var file_excel = 'BCChiTiet_'+moment().tz("Asia/Bangkok").format('DDMMYYYY')+'_'+moment().tz("Asia/Bangkok").format('HHmmss')+'.xlsx';
      var file_excel = this.data_popup_import_faild.link_err.split('/').pop();
      link.setAttribute('download', file_excel)
      document.body.appendChild(link)
      link.click()
    },
    async chooseFile($event) {
      this.$commonFuction.is_loading(true);
      var that=this
      console.log("asd",$event)
      //if (this.files.length > 0) {

      /*  this.$store.commit('setDataLoading', {content: 'Đang kiểm tra file import', icon: true});
        this.$store.commit('setLoading', true);
        var vm = this;*/
        var file = this.$refs.files.files[0];
        let formData = new FormData();
        formData.append('file', file)
      var headerUpload = {
        headers: {
          'Content-Type': 'multipart/form-data',
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        }
      };
        await ApiRepository.post('/_api/order/import-misa-excel', formData,headerUpload)
            .then(async (response) => {
              console.log("responseresponse:",response)
            //check status file
              var is_process = 0
              var callData  =  window.setInterval(async () => {
                this.$commonFuction.is_loading(false);
                if (is_process == 1){
                  return false
                }
                is_process = 1;
                var item_import  = (response["data"]["data"]) ? response["data"]["data"]:null
                var dataRequestCheck = {
                  url_file:item_import.url_file,
                }
                console.log("dataRequestCheckdataRequestCheck:",dataRequestCheck)
                try {
                  const headers = {
                    "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
                  };
                  var item_res = await ApiRepository.post('_api/order/check-import-excel-status', dataRequestCheck, {headers});
                  var item_check  = (item_res["data"]["data"]) ? item_res["data"]["data"]:null
                  console.log("item_checkitem_check:",item_check)
                  if (item_check){


                    var key_process = item_check.process
                    var success = item_check.success
                    var row_err = (item_check.error) ? item_check.error:0;
                    var total = item_check.total
                    var round_percent = Math.round(success / total * 100) / 100
                    const percentText = parseInt(round_percent * 100);
                    if (key_process == 'FINISH'){
                      clearInterval(callData);
                      this.update_process_bar('Import thành công',100);
                      setTimeout(function () {
                        alert('Import thành công')
                        location.reload();
                      }, 1000);
                    }else if (key_process == 'ERROR' || key_process == 'INSERT_ERROR'){
                      var number_error = item_check.error
                      var error_file = item_check.error_file
                      this.data_popup_import_faild.text_err =`Tập dữ liệu chứa ${number_error} dòng không hợp lệ, tải file chứa lỗi và điều chỉnh cho lần nhập dữ liệu tiếp theo.`
                      //this.data_popup_import_faild.link_err =this.$CoreService.baseurl()+'/'+error_file
                      this.data_popup_import_faild.link_err =error_file
                      clearInterval(callData);
                      this.data_popup_import_faild.display = true
                      this.$refs.files.value = null;
                      this.display_progress_bar = false
                      if (key_process == 'INSERT_ERROR'){
                        this.data_popup_import_faild.text_err1 =`- Import thành công ${success} dòng`
                        this.data_popup_import_faild.text_err2 =`- Import thất bại ${row_err} dòng`
                      }else {
                        this.data_popup_import_faild.text_err1 =null
                        this.data_popup_import_faild.text_err2 =null
                      }
                      //alert('Lỗi')
                    }else if (key_process == 'IMPORTING'){
                      //đang import
                      console.log("asdasd")
                      this.update_process_bar('Đang import dữ liệu',percentText);
                    }
                    else if (key_process == 'VERIFYING'){
                        //Đang kt dữ liệu
                      console.log("asdasd")
                      this.update_process_bar('Đang kiểm tra dữ liệu',percentText);
                    }
                    else if (key_process == 'VERIFIED'){
                      //đã kiểm tra
                      this.update_process_bar('Đã kiểm tra dữ liệu',percentText);
                    }
                  }
                  is_process = 0;
                  //
                  return true
                } catch (err) {
                  that.$toast.add({
                    severity: "error",
                    summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                    detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                    life: 3000,
                  });
                  console.log(err);
                  return false;
                }

               /* step++;
                if (step > vm.seconds) {
                  step = 0;
                  var stop = await vm.checkImportProcessing();
                  if (stop) {
                    vm.$store.commit('setLoading', false);
                    clearInterval(callData);
                  }
                }*/
              }, 5000);
            })
            .catch(async (error) => {
              console.log("errorerror:",error)
              /*this.$store.commit('setLoading', false);
              vm.$toast.add({ severity: 'error', summary: 'Thông báo', detail: error, life: 3000 })*/
            });

     // }
    },
    async click_import_order() {
      console.log("change_import_order:")
      const elementToClick = document.getElementById('input-file-order');
      const clickEvent = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      });
      elementToClick.dispatchEvent(clickEvent);
    },
    async list_parcel() {
      var gqLQueryListData = DataServices.getList('parcel');
      var where_data = {
        active: {_eq: true},
        deleted: {_eq: false},
      }
      var resData_data = await this.$apollo.mutate({
        mutation: gqLQueryListData,
        variables: {
          where_key:where_data ,
          /*offset:offset,
          limit:limit,*/
          orderBy:{ordinal: 'asc'}
        }
      });
      var listData = resData_data.data['parcel'];
      var map_key = {};
      for (var i=0;i<listData.length;i++){
        var item_data = listData[i]
        var name = item_data.name
        if (!map_key[name]){
          map_key[name] = 0;
        }
        map_key[name] = map_key[name]+1;
        listData[i]["name"] = name.padEnd(name.length + map_key[name]);
        console.log("item_data.typeitem_data.type:",item_data.type)
        if (item_data.type == 1){
          this.item_parcel_ol = listData[i];
          //console.log("this.item_parcel_ol:",this.item_parcel_ol)
        }
      }
      return listData;

    },
    async validateDataPopupPakage() {
      //var key_data = (this.data_popup_product.obj_product) ? this.data_popup_product.obj_product.key_data:null;
      var code_order_pakage = document.getElementById("code_order_pakage");
      // var is_err = 0;
      if (this.data_popup_update_pakage.obj_data.is_multiple == false) {
        if (this.$commonFuction.isEmpty(this.data_popup_update_pakage.obj_data.code)) {
          //     is_err = 1;
          code_order_pakage.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
        } else {
          var where_obj1 = {code: {'_eq': this.data_popup_update_pakage.obj_data.code}};
          console.log("where_obj1where_obj1:", where_obj1)
          var obj_data1 = await this.$CoreService.getObjectData('order', where_obj1);
          if (this.$commonFuction.isEmpty(obj_data1)) {
            this.data_popup_update_pakage.obj_data.code ='';
            code_order_pakage.setCustomValidity("Đơn hàng không hợp lệ");
          } else {
            this.data_popup_update_pakage.obj_data.list_order_check.push(obj_data1)
            this.order_id_pakage = obj_data1.id
            code_order_pakage.setCustomValidity("");
          }

        }
      }
      if (this.data_popup_update_pakage.obj_data.combine_packages){
        //if (!this.$commonFuction.isEmpty(this.order_id_pakage)) {
          var code_combine_packages = document.getElementById("code_combine_packages");
          // var is_err = 0;
          if (this.$commonFuction.isEmpty(this.data_popup_update_pakage.obj_data.code_combine_packages)) {
            //     is_err = 1;
            code_combine_packages.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
          } else {
            var where_obj = {
              code: {'_eq': this.data_popup_update_pakage.obj_data.code_combine_packages},
              //id: {_neq: this.order_id_pakage}
            };
            if (this.data_popup_update_pakage.obj_data.is_multiple == false) {
              if (!this.$commonFuction.isEmpty(this.order_id_pakage)) {
                where_obj["id"] = {_neq: this.order_id_pakage}
              }
            }else {
              var list_id_order_multiple = [];
              for (var g=0;g<this.list_order_select_item.length;g++){
                var item_order_data = this.list_order_select_item[g];
                list_id_order_multiple.push(item_order_data.id);
              }
              where_obj["id"] = {_nin: list_id_order_multiple}
            }

            var obj_data = await this.$CoreService.getObjectData('order', where_obj);
            if (this.$commonFuction.isEmpty(obj_data)) {
              this.data_popup_update_pakage.obj_data.code_combine_packages = ''
              code_combine_packages.setCustomValidity("Đơn hàng ghép kiện không hợp lệ");
            } else {
              console.log("obj_dataobj_data:",obj_data)
              this.data_popup_update_pakage.obj_data.list_order_check.push(obj_data)
              this.data_popup_update_pakage.obj_data.id_combine_packages = obj_data.id
              code_combine_packages.setCustomValidity("");
            }

          }
        //}
      }


      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()) {
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }

    },
    async validateDataPopupPackageCode(){
      var listDisplayCompany = AuthenticationApp.getListCompany();
      //get order where code = this.data_popup_update_pakage.obj_data.code and company_id in listDisplayCompany
      var where_obj = {
        code: {'_eq': this.data_popup_update_pakage.obj_data.code},
      };
      if (listDisplayCompany != null)
      {
        where_obj.company_id = {_in: listDisplayCompany}
      }
      console.log("where_objwhere_obj:",where_obj)
      var obj_data = await this.$CoreService.getObjectData('order', where_obj);
      if (this.data_popup_update_pakage.obj_data.is_multiple == false) {
        if (this.$commonFuction.isEmpty(obj_data)) {
          this.data_popup_update_pakage.obj_data.code = ''
          this.code_key++;
          alert("Đơn hàng không hợp lệ");
          return true
        } else {
          // this.order_id_pakage= obj_data.id
          return false
        }
      }else {
        return false
      }

    },
    async process_submit_pakage() {
      this.data_popup_update_pakage.obj_data.list_order_check = [];
      var that = this
      this.$commonFuction.is_loading(true);
      if (await this.validateDataPopupPackageCode()){
        this.$commonFuction.is_loading(false);
        return
      }
      if (await this.validateDataPopupPakage()) {
        // this.saving = false;
        // this.$commonFuction.is_loading(false);
        this.$commonFuction.is_loading(false);
        return
      }
      var mergedArrayOrder = [];
      var list_id_order_multiple = [];
      if (this.data_popup_update_pakage.obj_data.is_multiple == true) {
        for (var g=0;g<this.list_order_select_item.length;g++){
          var item_order_data = this.list_order_select_item[g];
          list_id_order_multiple.push(item_order_data.id);
        }
        mergedArrayOrder = Object.assign([], this.data_popup_update_pakage.obj_data.list_order_check, this.list_order_select_item);
      }else{
        mergedArrayOrder = this.data_popup_update_pakage.obj_data.list_order_check;
      }
      var cus_check = {};
      var count_check = 0;
      for (var u=0;u<mergedArrayOrder.length;u++){
        var item_check = mergedArrayOrder[u];
        var customer_id_check = (!this.$commonFuction.isEmpty(item_check.customer_id)) ? item_check.customer_id:null;
        if (this.$commonFuction.isEmpty(cus_check[customer_id_check])) {
          cus_check[customer_id_check] = 0;
          count_check++;
        }
      }
      if (this.data_popup_update_pakage.obj_data.combine_packages==true) {
        if (count_check > 1) {
          this.$commonFuction.is_loading(false);
          alert("Các đơn hàng tồn tại khách hàng khác nhau. Bạn không thể ghép kiện!");
          this.data_popup_update_pakage.obj_data.code = ''
          this.data_popup_update_pakage.obj_data.code_combine_packages = ''
          this.code_key++;
          return;
        }
      }


      if (this.data_popup_update_pakage.list_pakage_data.length <1){
        this.$commonFuction.is_loading(false);
        alert("Vui lòng thêm kiện hàng");//
        return;
      }else {
        if (this.data_popup_update_pakage.obj_data.combine_packages==false){
            var list_package_ = this.data_popup_update_pakage.list_pakage_data
            for (var k=0;k<list_package_.length;k++){
                var item_pa = list_package_[k];
                var parcel_qty = item_pa.parcel_qty
                var parcel_id_key = item_pa.parcel_id
                if ( this.$commonFuction.isEmpty(parcel_qty) || this.$commonFuction.isEmpty(parcel_id_key)) {
                  alert("Vui lòng nhập đầy đủ thông tin kiện hàng")
                  this.$commonFuction.is_loading(false);
                  return;
                }
                if (parcel_qty <= 0 ){
                  alert("Vui lòng nhập số lượng kiện hàng lớn hơn 0")
                  this.$commonFuction.is_loading(false);
                  return;
                }
            }
        }
      }
      //data_popup_update_pakage.obj_data.code
      var dataRequest = {
        is_multiple:this.data_popup_update_pakage.obj_data.is_multiple,
        list_id_order_multiple:list_id_order_multiple,
        id:this.order_id_pakage,
        combine_packages:this.data_popup_update_pakage.obj_data.combine_packages,
        list_pakage_data:this.data_popup_update_pakage.list_pakage_data,
        id_combine_order:this.data_popup_update_pakage.obj_data.id_combine_packages,
      }

      try {
        const headers = {
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
        await ApiRepository.post('/_api/order/process-update-pakage', dataRequest, {headers});
        that.$toast.add({
          severity: "success",
          summary: "Thông báo",
          detail: "Cập nhật thành công",
          life: 3000,
        });

        //location.reload()
        this.data_popup_update_pakage.display = false;
        this.$commonFuction.is_loading(false);
        this.componentKey++;
        return true
      } catch (err) {
        this.$commonFuction.is_loading(false);
        var message = err.response.data.message;
        console.log("messagemessage:",message)
        if (message=='UPDATE_STATUS_ERR'){
          //var list_err = err.response.data.data
          alert(that.$constants.STATUS_ORDER_TEXT["ST005"])
          this.data_popup_update_pakage.obj_data.code = ''
          this.data_popup_update_pakage.obj_data.code_combine_packages = ''
          this.code_key++;
          //alert("Không thể cập nhật trạng thái")
        }else if (message=='JOINT_ORDER_COMBINE_ERR') {
          alert("Bạn không thể ghép kiện với đơn hàng "+this.data_popup_update_pakage.obj_data.code_combine_packages)
          this.data_popup_update_pakage.obj_data.code = ''
          this.data_popup_update_pakage.obj_data.code_combine_packages = ''
          this.code_key++;
        }else if (message=='JOINT_ORDER_COMBINE_OTHER_CUSTOMER_ERR') {
          alert("Đơn hàng không cùng khách hàng bạn không thể ghép kiện.")
          this.data_popup_update_pakage.obj_data.code = ''
          this.data_popup_update_pakage.obj_data.code_combine_packages = ''
          this.code_key++;
        }
        else {
          that.$toast.add({
            severity: "error",
            summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
            detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
            life: 3000,
          });
        }
        console.log(err);
        return false;
      }
    },
    async add_pakage_popup() {
      var item_pakage = {
        parcel_id:null,
        parcel_qty:0,
        key_data:(new Date()).getTime()+this.$commonFuction.makeId(8),
        list_parcel_key:[]
      }
      this.data_popup_update_pakage.list_pakage_data.push(item_pakage)
    },
    async delete_pakage_popup(key_data) {
      var list_pakage_data = [];
      var list_pakage_data_convert = this.data_popup_update_pakage.list_pakage_data;
      for (var i=0;i<list_pakage_data_convert.length;i++){
        var item_data = list_pakage_data_convert[i];
        var key_data_check = item_data.key_data;
        if (key_data!==key_data_check){
          list_pakage_data.push(item_data)
        }
      }
      this.data_popup_update_pakage.list_pakage_data = list_pakage_data
    },
    action_list_select_item(data) {
      //Lấy danh sách đơn hàng
      console.log("data:",data)
      this.list_select_item = data["list_select_item"]
      this.list_map_code_order = data["list_map_code_order"]
      if (this.list_select_item.length > 0){
        this.dislay_button=0
      }else {
        this.dislay_button=1
      }
    },
    async process_click_status(type_status,title_page='',button_name='') {
      /*if (type_status == this.$constants.STATUS_ORDER.PACKAGED){
        this.data_popup_update_pakage.obj_data.is_multiple =true;
        this.data_popup_update_pakage.obj_data.list_code =[];
        this.data_popup_update_pakage.obj_data.code ='';
        this.data_popup_update_pakage.obj_data.combine_packages =false;
        this.data_popup_update_pakage.obj_data.code_combine_packages ='';
        this.data_popup_update_pakage.list_pakage_data =[];
        this.data_popup_update_pakage.list_parcel = await this.list_parcel()
        this.data_popup_update_pakage.display = true;
        //add default 1 kiện
        this.add_pakage_popup()
        return  false;
      }*/
      var pageTransaction = "status_"+(new Date()).getTime();
      //Lấy danh sách đơn hàng
      var data_redirect = {
        list_code:this.list_select_item,
        type_status:type_status,
        title_page:title_page,
        button_name:button_name,
      }
      console.log("this.list_select_item",data_redirect);
      //var list_err = []
      var list_order_select_item = []
      var order_note_suggest_address_customer = []
      if (this.list_select_item.length>0){
        //var list_order_code = []
          for (var i=0;i<this.list_select_item.length;i++){
            var code_check =this.list_select_item[i]
            var item_order = this.list_map_code_order[code_check]
            list_order_select_item.push(item_order)
            if (this.$commonFuction.isEmpty(item_order.suggest_address_customer)) {
              order_note_suggest_address_customer.push(code_check)
            }
         /*  var check_ord =await  this.$CoreService.check_type_order(item_order.code, this.list_status_change,item_order.order_status.code,type_status)
          if (check_ord == 0){
            list_err.push(code_check)
          }*/

        }
        this.$commonFuction.is_loading(true);
        var  data_check_res = await  this.$CoreService.check_status_order(this.list_select_item, 2,type_status)
        console.log("data_check_res:",data_check_res)
        this.$commonFuction.is_loading(false);
        if (data_check_res) {
          alert(data_check_res["text_err"]);
          return false
          /*
          list_err = data_check_res["data"]
          if (list_err.length > 0) {
            alert("Các đơn hàng không thể thay đổi trạng thái này:" + list_err.join(', '));
            return false
          }*/
        }
      }
      this.list_order_select_item = list_order_select_item
      localStorage.setItem(pageTransaction.toString(), JSON.stringify(data_redirect));
      if (type_status == this.$constants.STATUS_ORDER.COORDINATION_COMPLETED){
        this.$router.push({ path: "/admin/list-order_shipping/add",query: { status_transaction: pageTransaction.toString() }  })
      }else if (type_status == this.$constants.STATUS_ORDER.UPDATED_DISPATCHER_ADDRESS){
        if (order_note_suggest_address_customer.length>0){
          alert("Các đơn hàng không có địa chỉ điều phối :"+ order_note_suggest_address_customer.join(', ') );
          return false;
        }
        this.data_popup_confirm_address.display = true
      }
      else if (type_status == this.$constants.STATUS_ORDER.PACKAGED){
        if (this.list_select_item.length>0){
          this.data_popup_update_pakage.obj_data.is_multiple =true;
          this.data_popup_update_pakage.obj_data.list_code =this.list_select_item;
        }else {
          this.data_popup_update_pakage.obj_data.is_multiple =false;
          this.data_popup_update_pakage.obj_data.list_code =[];
        }
        this.data_popup_update_pakage.obj_data.code ='';
        this.data_popup_update_pakage.obj_data.combine_packages =false;
        this.data_popup_update_pakage.obj_data.code_combine_packages ='';
        this.data_popup_update_pakage.list_pakage_data =[];
        this.data_popup_update_pakage.list_parcel = await this.list_parcel()
        this.data_popup_update_pakage.display = true;
        //add default 1 kiện
        this.add_pakage_popup()
        if (this.list_select_item.length>0){
          this.changeOrderMultilplePackage();
        }
        return  false;
      }
      else {
        this.$router.push({ path: "/admin/list-process-status-order",query: { status_transaction: pageTransaction.toString() }  })

      }
      return type_status
    },
    initParams() {
      this.$store.commit('setLoading', true);
      this.modelName = this.$route.meta.model;
      this.dataModel = DataServices.getModel(this.modelName);
      this.title = this.dataModel.label;
      this.callbackOptions();
    },
    onChange() {
      this.$store.commit('setLoading', true);
      this.$apollo.queries.listData.refresh()
    },
    getFilterOptions(name) {
      var field = this.dataModel.getField(name)
      var result = []
      if (field.type == 'Selection') {
        //result = field.options;
        if (field.options ) result = field.options;
        else if(field.models && this.options[name]) result = this.options[name];
      } else {
        result = this.filterOptions[name] != null ? [...this.filterOptions[name]] : []
      }
      console.log("field.type:",field.type);
      console.log("resultresultresultresult11:",result);
      return result;
    },
    getRowActions() {
      var actions = {}
      var that = this;
      /*this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ADMIN,this.$constants.ROLE.ADD).then( (res)  => {
       if (res){
         actions.header = {name: 'add', icon: 'plus', path: `list-${this.modelName}/add`};
       }
      })*/
      /*if (this.role.add) {
        actions.header = {name: 'add', icon: 'plus', path: `list-${this.modelName}/add`};
      }*/
      //if ((await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ADMIN,this.$constants.ROLE.ADD))){

      // actions.header = { name: 'add', icon: 'plus'};
      actions.rows = function (rowData) {
        var buttons = {}
        buttons.view = { name: 'view', icon: 'eye', key: 'id', path: `list-${that.modelName}/view/`};
       /* if (that.role.edit) {
          buttons.edit = {name: 'edit', icon: 'pencil', key: 'id', path: `list-${that.modelName}/edit/`};
        }*/
        // buttons.edit = { name: 'edit', icon: 'pencil', key: 'id', path: `${that.modelName}/edit/`}
       /* if (that.role.delete) {
          buttons.delete = {name: 'delete', icon: 'trash', key: 'id'}
        }*/

        if (that.dataModel.actions) {
          var modelaction = that.dataModel.actions(rowData)
          Object.assign(buttons, modelaction);
        }
        return buttons
      }
      return actions
    },
    async callbackOptions() {
      console.log("tettststststst:ssssss")
      var fields = this.dataModel.getDisplayFields();
      console.log("tettststststst:",fields)
      var attributes = [];
      for (let index = 0; index < fields.length; index++) {
        const element = fields[index];
        if (element.models !== undefined && element.models !== null) {
          var optionsData = [];
          attributes =[]
          attributes.push(element.models.key.key);
          attributes.push(element.models.display_key);
          //console.log("attributesattributesattributes:",attributes)
          //var where_status = {}
          /*if( element['name']== "rel_status"){
            where_status =  {
              type: {'_eq': 'ORDER'}
            }
          }*/
          var where_ = {
          }
          if (element.where_option)
            where_ = element.where_option;
          if( element['name']== "name_group_filter"){
            where_ =  {
              active: {_eq: true},
              deleted: {_eq: false},
            }
            element.models.tableName = 'group'
          }
          console.log("element.models:",element.models);
          var variables = {where_key : where_}
          if( element['name']== "current_order_status_id"){
            variables["orderBy"] = {ordinal: "asc"}
          }
          var {data} = await this.$apollo.query({
            query: DataServices.getList(element.models.tableName, {"fields" : attributes.join(',')}),
            variables: variables
          })
          if (data) {
            var result = data[Object.keys(data)];
            if (result) {
              for (let index = 0; index < result.length; index++) {
                const ele = result[index];
                optionsData.push({value: ele[attributes[0]], label: ele[attributes[1]]})
              }
            }
          }
          console.log("optionsDataoptionsData:",optionsData)
          this.options[element.key] = optionsData;
        }
        this.componentKey++;
      }

    },
    async onActionClicked(payload) {
      var action = payload.action
      var data = null
      if (payload.data) {
        data = payload.data
      }
      var filter = payload?.lazyParams?.filters ?? []
      var list_filter = []
      for (let key in filter) {
        //if (filter.hasOwnProperty(key)) { // Ensure the property belongs to the object itself, not inherited
        console.log(`wwwwwww11 ${key}: ${filter[key]}`);
        //}
        var itemdata={};
        itemdata[key] = filter[key];
        list_filter.push(itemdata)
      }
      payload.lazyParams.list_filter = list_filter;
      var pageTransaction = (new Date()).getTime();
      switch (action.name) {
        case 'view':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
            this.$router.push({ path: action.path + data.data[action.key] ,query: { page_transaction: pageTransaction.toString() }})
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'edit':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            console.log("payload.lazyParams:111"+pageTransaction,payload.lazyParams);
            localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
            this.$router.push({ path: action.path + data.data[action.key],query: { page_transaction: pageTransaction.toString() }  })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'delete':
          localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
          this.page_transaction = pageTransaction;
          this.deleteId = data.data[action.key]
          this.deleteName = data.data.name
          this.showDeleteDialog = true
          break
        case 'add':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path })
          } else  {
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'custom':
          await action.handler(data.data, this)
          this.componentKey++
          break
      }
    },
    async deleteData() {
      this.showDeleteDialog = false;
      console.log("testttttttttt");
      this.$commonFuction.is_loading(true);
      var resDataAccount = await this.$CoreService.getAccountByUid(this.deleteId);
      if (resDataAccount && resDataAccount.length > 0){
        //res[0].id;
        // var account_id = resDataAccount[0].id;
        // var phone = this.$commonFuction.convertPhone(resDataAccount[0].account);
        var uid = this.deleteId;
        var that = this;
        //Xóa account cũ
        
        // id mới dc thêm vào
        var userUpdate = {
          'deleted': true,
        };
        var accountUpdate = {
          'deleted': true,
        }
        var queryUpdate = `
            mutation update_users_multil($users_object: users_set_input,$accounts_object: accounts_set_input,$group_user_object: group_user_set_input) {
              update_users(where: { id: {_eq: "${uid}"}}, _set: $users_object) {
                affected_rows
                returning {
                  id
                }
              }
              update_accounts(where: { uid: {_eq: "${uid}"}}, _set: $accounts_object) {
                affected_rows
                returning {
                  id
                }
              }
            }
          `;

        let variables = {
          users_object: userUpdate,
          accounts_object: accountUpdate,
        };
        that.$apollo.mutate({
          mutation: gql(queryUpdate),
          variables: variables
        }).then(async (res) => {
          console.log(res);
        }).then(() => {
          var router_back = {path: '/admin/list-users'};
          if (!that.$commonFuction.isEmpty(router_back)){
            console.log("that.page_transaction:",that.page_transaction);
            router_back.query = { page_transaction: that.page_transaction.toString() };
          }
          that.$router.push(router_back);
          that.$commonFuction.is_loading(false);
          that.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Xóa dữ liệu thành công', life: 3000 })
          //that.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Lưu dữ liệu thành công', life: 3000 })
          window.location.reload()
        });
      }
    }
  },
  /*apollo: {
    listData: {
      query() {
        this.modelName = this.$route.meta.model;
        console.log('8*************************',this.modelName);
        return DataServices.getList(this.modelName);
      },
      variables () {
        // Use vue reactive properties here
        return this.whereKeyList;
      },
      update(data) {
        console.log('this.dataModel.tableName',data);
        var listData = data[this.dataModel.tableName]
        this.title = this.dataModel.label + ' '
        this.dataModel.processData(listData)
        this.$store.commit('setLoading', false);
        this.componentKey += 1;
        console.log('data[this.dataModel.tableName]',data[this.dataModel.tableName]);
        return data[this.dataModel.tableName]
      },
      fetchPolicy: 'network-only',
      error() {
        this.$store.commit('setLoading', false);
      },
      skip() {
        return !this.modelName
      }
    }
  }*/

}
</script>
<style lang="css">
.p-calendar .p-datepicker { width: 280px; padding: 0px; padding-top: 5px;}
.p-calendar .p-datepicker td, .p-calendar .p-datepicker td a {padding: 0px !important;}
.p-calendar .p-datepicker td span {padding: 0px !important; width: 30px !important; height: 30px !important;}
.p-datepicker .p-datepicker-header{padding: 5px !important;}
/*.style-button-common{
  background: #C40380 0% 0% no-repeat padding-box;
  border-radius: 3px;
  color: #FFFFFF;
}*/
</style>
<style lang="scss">
.list_group_order {
  .group-button-list-top {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 16px;
    padding-right: 0px;

    .style-button-common {
      margin-left: 8px;
     /* margin-right: 8px;*/
      margin-top: 8px;
      height: 48px;
    }

    .style-button-common:last-of-type {
      margin-right: 0px;
    }
  }
.group-button-list-top-custom{
  display: inline;
  text-align: right;
}
  .style-popup-update-pakage,style-popup-confirm-address
  {
    width: 600px;
  }
  .style-popup-update-pakage{
    .style_inpuit_order{
      width: 100%;
    }
    .pakage_popup_dropdown{
      width: 100%;
    }
    .item-right-popup{
      width: 212px !important;
    }
    .item-left-popup{
      width:calc(100% - 212px) !important;
    }
    .style_group_pakage{
      position: relative;
      .popup_qty_pakage{
        width: 166px;
      }
      .style_icon_del_pakage{
        cursor: pointer;
        position: absolute;
        width: 38px;
        height: 32px;
        right: 0px;
        margin-top: auto;
        margin-bottom: auto;
        top: 0px;
        bottom: 0px;
      }
    }
  }
  .import-order-btn{
    position: relative;
    display: inline;
    .input-file-order{
      position: absolute;
    }
  }
  .text_err_row{
    padding-top: 2px;
    padding-bottom: 2px;
  }
}
.scan-dialog video {
  width: 100% !important;
}
.scan-dialog div.laser {
  width: 100% !important;
  margin-left: 0 !important;
  height: 2px;
}
.scan-dialog div.overlay-element {
  background-color: transparent !important;
}
.download_file_sample{
  text-decoration: underline;
  font-style: italic;
  font-size: 18px;
  color: #C40380;
}

</style>